import { render, staticRenderFns } from "./SwitchGroup.vue?vue&type=template&id=8d96de44&scoped=true&"
import script from "./SwitchGroup.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SwitchGroup.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./SwitchGroup.vue?vue&type=style&index=0&id=8d96de44&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d96de44",
  null
  
)

export default component.exports